import {Component, OnDestroy, OnInit} from '@angular/core';
import {EncounterService} from "../../Services/encounter.service";
import {Subject, takeUntil} from "rxjs";
import {Noter} from "../../Classes/noter";

@Component({
  selector: 'app-noter',
  templateUrl: './noter.component.html',
  styleUrls: ['./noter.component.scss'],
})
export class NoterComponent implements OnInit, OnDestroy {
  destroy$:Subject<boolean> = new Subject();
  noter:Noter|null;
  constructor(public encounterService:EncounterService) { }

  ngOnInit(): void {
    this.encounterService.noter$.pipe(takeUntil(this.destroy$)).subscribe(this.updateNoter);
  }

  updateNoter = (noter:Noter)=> {
    this.noter = noter;
    if(navigator.vibrate && this.noter !== null){
      navigator.vibrate(50);
    }
  }

  closeHandler() {
    this.noter = null;
    this.encounterService.popNoter();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
