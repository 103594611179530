import {Component} from '@angular/core';
import {HealthService} from "./Services/health.service";
import {Subject} from "rxjs";
import {EncounterService, uimode} from "./Services/encounter.service";
import {SubscribeService} from "./Services/subscribe.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{
  title = 'D&D Initiative Tracker';
  modalDismissed=false;
  destroy$:Subject<boolean> = new Subject();
  showFAQ = false;
  showDonate = false;


  constructor(public healthService:HealthService, public encounterService:EncounterService, public subscribeService:SubscribeService) {}

  ngOnInit(): void {
    const self=this;
    window.addEventListener('popstate', function() {
      self.goBack();
    })
    this.encounterService.getLocalStorageEncounter();
    this.subscribeService.checkSubscription();
  }

  goBack():void {
    this.encounterService.setMode(uimode.LOAD);
  }

  requestNotifications(){
    this.modalDismissed = true;
    this.subscribeService.requestSubscription();
  }

  link(url:string){
    window.open(url, '_blank');
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
