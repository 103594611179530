<div>Join Encounter With Code</div>
<div>
  <input type="text" maxlength="4" [(ngModel)]="encounterCode" [disabled]="encounterService.callingApi$ | async" />
</div>
<div class="error">{{encounterService.getEncounterError$|async}}</div>
<div *ngIf="encounterService.encounter$|async">
  <button (tap)="reloadEncounter()">Rejoin Encounter {{(encounterService.encounter$|async)?.id}}</button>
</div>
<div>
  <button (tap)="generateEncounter()">New Encounter</button>
</div>
<div>
  <button (tap)="editLocalPlayers()">Edit Local Players</button>
</div>
<div>
  <button (tap)="editDmOptions()">Run Encounter Options</button>
</div>
<div class="error">{{encounterService.createEncounterError$|async}}</div>
