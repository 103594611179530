import { Pipe, PipeTransform } from '@angular/core';
import {Character} from "./character";
import {Encounter} from "./encounter";

@Pipe({
  name: 'turn'
})
export class TurnPipe implements PipeTransform {

  transform(character: Character,encounter:Encounter): boolean {
    if(character && encounter){
      return character.id === encounter.turnOrder[encounter.turnIndex];
    } else {
      return false;
    }
  }

}
