export class DmOptions {
  showDmNotes:boolean = false;
  showPlayerNotes:boolean = false;
  _showCreatureHp:boolean = false;
  showPlayerHp:boolean = false;
  _showCreatureHpToPlayers:boolean = false;
  get showCreatureHp(){
    return this._showCreatureHp;
  }
  set showCreatureHp(val:boolean){
    this._showCreatureHp = val;
    if(!this._showCreatureHp) {
      this.showCreatureHpToPlayers = false;
    }
  }
  get showCreatureHpToPlayers(){
    return this._showCreatureHpToPlayers;
  }
  set showCreatureHpToPlayers(val:boolean){
    this._showCreatureHpToPlayers = val;
    if(this._showCreatureHpToPlayers){
      this.showCreatureHp = true;
    }
  }
}
