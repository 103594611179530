<div class="wrapper">
  <div class="header">
    Edit Local Players
  </div>
  <app-npc-row *ngFor="let local of localPlayers"
               [character]="local"
               [showBonus]="true" [showDeleteBtn]="true" [showEditBtn]="true"
               (doEdit)="openAddPlayerForm($event)"
               (doDelete)="deleteLocalPlayer($event)"></app-npc-row>
  <div class="expire" *ngIf="!localPlayers.length">No Local Players have been added yet.</div>
  <div class="buttons">
    <div>
      <div class="icon-btn" (tap)="openAddPlayerForm()">
        <img class="bouncy" src="assets/icons/battle.svg" oncontextmenu="return false;">
        <span>Add Player</span>
      </div>
    </div>
  </div>
  <!-- ADD Player -->
  <div class="bd" [ngClass]="{open:mode === 1}">
    <app-add-player-form [local]="true" (closeFormEvent)="closeAddPlayer()"></app-add-player-form>
  </div>
</div>
