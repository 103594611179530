import { Pipe, PipeTransform } from '@angular/core';
import {NPC} from "./npc";

@Pipe({
  name: 'hiddenCreatures'
})
export class HiddenCreaturesPipe implements PipeTransform {

  transform(characters: any[],all?:boolean): any {
    if(all) return characters;
    return characters.filter(c=>{
      if(!c) return c;
      return !c.hidden;
    });
  }

}
