import {Component, ElementRef, EventEmitter, Input, OnInit, Output, SecurityContext, ViewChild} from '@angular/core';
import {NpcIcons} from "../../Classes/npc-icons";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {EncounterService} from "../../Services/encounter.service";
import {Player} from "../../Classes/player";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import Compressor from 'compressorjs'

@Component({
  selector: 'app-add-player-form',
  templateUrl: './add-player-form.component.html',
  styleUrls: ['./add-player-form.component.scss']
})
export class AddPlayerFormComponent implements OnInit {
  @Input() local = false;
  @Output() closeFormEvent = new EventEmitter<void>();
  @ViewChild('playerNameField') playerNameField:ElementRef;
  icons = new NpcIcons();
  uploadedIcon: SafeUrl = '';
  playerForm:FormGroup = this.fb.group(
    {
      id:[''],
      name:['',Validators.required],
      fileName:[''],
      initBonus:[0]
    }
  );

  constructor(public encounterService:EncounterService, public fb:FormBuilder, private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {}

  async onFileChanged(event: any) {
    const self = this;
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      new Compressor(file, {
        width:64,
        height:64,
        resize:'cover',
        convertSize:1000000*0.5, //half meg
        success(result) {
          console.log('compressed file size',(1024/result.size));
          const reader = new FileReader();
          reader.readAsDataURL(result);
          reader.onload= () => {
            const result = self.domSanitizer.bypassSecurityTrustUrl(reader.result as string);
            self.uploadedIcon = result;
          }
        }
      })
    }
  }

  async addPlayer(){
    if(this.playerForm.status === 'VALID'){
      const player = this.playerForm.getRawValue() as Player;
      player.icon = this.uploadedIcon !=='' ? this.domSanitizer.sanitize(SecurityContext.URL, this.uploadedIcon):'';
      if(this.local) {
        this.encounterService.addLocalStoragePlayer(player);
        this.closeFormEvent.emit();
      } else {
        this.encounterService.addPlayer(player).then(result=>{
          this.closeFormEvent.emit();
        }).catch(error=>{
          console.error(error);
          alert('error adding player');
        });
      }
    } else {
      this.playerForm.markAllAsTouched();
    }
  }

  handleTextHighlight(){
    const native = this.playerNameField.nativeElement;
    if(native.selectionStart - native.selectionEnd){
      native.removeAllRanges();
    } else {
      native.select();
    }
  }

  cancelAddPlayer(){
    this.closeFormEvent.emit();
  }
}
