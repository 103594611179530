<div class="wrapper" [formGroup]="dmOptionsForm">
  <div class="header">
    Edit Encounter Options
  </div>
  <div>
    These options apply when you create an encounter. Encounters created by others will have their own options applied.
  </div>
  <div class="header">Show Notes</div>
  <div class="expire">Show notes about creatures and players only visible to you</div>
  <div>
    <label class="switch">
      <input type="checkbox" formControlName="showDmNotes">
      <span class="slider round"></span>
    </label>
  </div>
  <div class="header">Show Player Notes</div>
  <div class="expire">Show notes about creatures and players visible to you and all players</div>
  <div>
    <label class="switch">
      <input type="checkbox" formControlName="showPlayerNotes">
      <span class="slider round"></span>
    </label>
  </div>
  <div class="header">Show Creature HP Counter</div>
  <div class="expire">Show a counter for HP on creatures</div>
  <div>
    <label class="switch">
      <input type="checkbox" (change)="handleShowCreatureHpChange($event)" formControlName="showCreatureHp">
      <span class="slider round"></span>
    </label>
  </div>
  <div class="header">Show Creature HP to Players</div>
  <div class="expire">Shows the Creature HP counter to players<br>(Be sure you want to do this!)</div>
  <div>
    <label class="switch">
      <input type="checkbox" (change)="handleShowCreatureHpToPlayersChange($event)" formControlName="showCreatureHpToPlayers">
      <span class="slider round"></span>
    </label>
  </div>
  <!--
  <div class="header">Show Player HP Counter</div>
  <div class="expire">Show a counter for HP of players<br>(The value is player controlled)</div>
  <div>
    <label class="switch">
      <input type="checkbox" formControlName="showPlayerHp">
      <span class="slider round"></span>
    </label>
  </div>
  -->
  <div class="buttons">
    <div>
      <div class="icon-btn" (tap)="submit()">
        <img class="bouncy" src="assets/icons/plus.svg" oncontextmenu="return false;">
        <span>Update</span>
      </div>
    </div>
    <div class="divider"></div>
    <div>
      <div class="icon-btn" (tap)="cancel()">
        <img class="bouncy" src="assets/icons/cancel.svg" oncontextmenu="return false;">
        <span>Cancel</span>
      </div>
    </div>
  </div>
</div>
