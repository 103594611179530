<div class="header">D&D Initiative Tracker FAQ</div>
<div class="body">
  <h2>What is this?</h2>
  <p>
    The initiative tracker lets you create Encounters and add players and creatures to the encounter. Then, you can run the encounter to cycle through each character's turn.
    <br><br>
    Turn order is randomly chosen but each character can add a bonus percent to their initiative. The higher the bonus, the more likely that character will be first.
  </p>
  <h2>How do I start?</h2>
  <p>
    If you're the one running the Encounter (the DM) then start by clicking the 'Create Encounter' button. Share the Encounter ID with your players. Then add your creatures (NPCs) to the encounter.
    <br> <br>
    If you're a player, wait for your DM to create an encounter and share the encounter ID. Type the encounter ID into the text box on the main page. Then add your players.
  </p>
  <h2>I've added my creatures and players, now what?</h2>
  <p>
    If you're the DM, click the 'Start Encounter' button. If you're a player, wait for your DM to do that.
  </p>
  <h2>How does running an encounter work?</h2>
  <p>
    Once the encounter has begun, each player and the DM can take turns. If you are a player, you will have to wait for your character's to take any actions.
    Players will be able to see the current character's turn and if characters are incapacitated or dead.
    <br><br>
    The DM will have several options for both players and creatures. You can click on the icon next to the character to set options for that character.
    The DM is also allowed to end the turn for both players and creatures.
  </p>
  <h2>Can I manage my players outside of an encounter?</h2>
  <p>
    Yes! Click the 'Edit Local Players' button to manage players outside of the encounter.
  </p>
</div>
<div class="buttons"><button (tap)="handleClose()">Close</button></div>
