<div class="wrapper">
  <div class="header" (tap)="copyToClip()">
    Encounter: {{encounter.id}} <img class="bouncy" alt="share" src="assets/icons/share.svg" [ngClass]="{copied:copied}">
  </div>
  <div class="expire">Expires:{{encounterService.getEncounterExpire('yyyy-MM-dd h:mm aaa')}}</div>
  <div class="header">Players</div>
  <app-npc-row *ngFor="let player of encounter.players"
               [character]="player" [showBonus]="true"></app-npc-row>
  <div class="expire" *ngIf="!encounter.players?.length">No Players have joined yet.</div>
  <div class="header">Creatures</div>
  <app-npc-row *ngFor="let npc of encounter.npcs"
               [character]="npc"
               [showBonus]="true" [showHiddenBtn]="true" [showEditBtn]="true" [showDeleteBtn]="true"
               [showHp]="(encounterService.localDmOptions$|async).showCreatureHp"
               [showNotes]="(encounterService.localDmOptions$|async).showDmNotes"
               [showPlayerNotes]="(encounterService.localDmOptions$|async).showPlayerNotes"
               (doEdit)="openAddCreatureForm($event)"
               (doDelete)="deleteCreature($event)"></app-npc-row>
  <div class="expire" *ngIf="!encounter.npcs?.length">No Creatures have been added yet.</div>
  <div class="buttons">
    <div *ngIf="encounter.npcs?.length">
      <div class="icon-btn" (tap)="startEncModalShow = true" >
        <img class="bouncy" alt="Start Encounter" src="assets/icons/battle.svg" oncontextmenu="return false;">
        <span>Start Encounter</span>
      </div>
    </div>
    <div class="divider" *ngIf="encounter.npcs?.length"></div>
    <div>
      <div class="icon-btn" (tap)="openAddCreatureForm()">
        <img class="bouncy" alt="Add Creature" src="assets/icons/monster.svg" oncontextmenu="return false;">
        <span>Add Creature</span>
      </div>
    </div>
  </div>
  <!-- ADD CREATURE -->
  <div class="bd" [ngClass]="{open:mode === 1}">
    <app-add-creature-form (closeFormEvent)="closeAddCreature()"></app-add-creature-form>
  </div>
  <!-- Button Modal -->
  <div class="btn-modal" [ngClass]="{open:startEncModalShow}">
    <div>
      <div class="hold">
        <div class="load" [ngClass]="{fillup:startEncCounter>0}"></div>
        <img alt="Start Encounter" src="assets/icons/hand-cursor.svg" oncontextmenu="return false;" (press)="startEncounterStartInterval()" (pressup)="startEncounterStopInterval()">
      </div>
      <span>Hold to Start Encounter</span>
      <img class="bouncy" alt="Cancel" class="cancel" src="assets/icons/cancel.svg" oncontextmenu="return false;" (tap)="startEncModalShow = false">
      <span>Cancel</span>
    </div>
  </div>
</div>
