<div [formGroup]="playerForm" class="wrapper">
  <div class="header">{{playerForm.controls['id'].getRawValue()!==''?'Edit':'Add'}} Player</div>
  <div class="form-title">Name</div>
  <div class="form-wrapper">
    <div class="selected-icon">
      <div [ngClass]="{'unselected':uploadedIcon===''}">
        <img *ngIf="uploadedIcon && uploadedIcon!=''" [src]="uploadedIcon">
        <input type="file" class="file" (change)="onFileChanged($event)" formControlName="fileName">
      </div>
    </div>
    <input type="text" maxlength="20" (keyup.enter)="playerNameField.blur()"
           [placeholder]="playerForm.controls['name'].invalid && (playerForm.controls['name'].dirty || playerForm.controls['name'].touched)?'Name Required':'Add Name'"
           [ngClass]="{invalid:playerForm.controls['name'].invalid && (playerForm.controls['name'].dirty || playerForm.controls['name'].touched)}"
           #playerNameField (tap)="handleTextHighlight()" formControlName="name">
  </div>
  <div class="subtext">Click icon to upload new file</div>
  <div class="form-title">Initiative Bonus %</div>
  <div>
    <app-number-selector formControlName="initBonus" [min]="0" [max]="100"></app-number-selector>
  </div>
  <div class="buttons">
    <div>
      <div class="icon-btn" (tap)="addPlayer()" >
        <img class="bouncy" alt="Add Player" src="assets/icons/plus.svg" oncontextmenu="return false;">
        <span>{{playerForm.controls['id'].getRawValue()!==''?'Edit':'Add'}} Player</span>
      </div>
    </div>
    <div class="divider"></div>
    <div>
      <div class="icon-btn" (tap)="cancelAddPlayer()">
        <img class="bouncy" alt="Cancel" src="assets/icons/cancel.svg" oncontextmenu="return false;">
        <span>Cancel</span>
      </div>
    </div>
  </div>
</div>
