import { Injectable } from '@angular/core';
import {SwPush} from "@angular/service-worker";
import {HttpClient} from "@angular/common/http";
import {EncounterService} from "./encounter.service";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SubscribeService {
  private readonly _notificationsEnabled = new BehaviorSubject<boolean>(true);
  readonly notificationsEnabled$ = this._notificationsEnabled.asObservable();
  private readonly _callingApi = new BehaviorSubject<boolean>(false);
  readonly callingApi$ = this._callingApi.asObservable();

  constructor(private _swPush:SwPush, public http:HttpClient, public encounterService:EncounterService) {}

  checkSubscription() {
    this._callingApi.next(true);
    if(this.encounterService.ownerid){
      this.callApiGetSubscription().subscribe({
        next:()=>{
          this._callingApi.next(false);
          this._notificationsEnabled.next(Notification.permission === 'granted');
        },
        error:()=>{
          this._callingApi.next(false);
          this._notificationsEnabled.next(false);
        }
      })
    } else {
      this._callingApi.next(false);
      this._notificationsEnabled.next(false);
    }
  }
  requestSubscription = async () => {
    if (!this._swPush.isEnabled) {
      return;
    }
    this._callingApi.next(true);
    this._swPush.requestSubscription({
      serverPublicKey: 'BH_pfEHh2W6hF83Sy6IqT-bsG89bHmfC79Yc7-x7jPoNH15g2DV62vOik7FoIP3t6SdbdfHBw9XfQF5Jvs21CL4'
    }).then((result) => {
      this.callApiPostSubscription(result).subscribe({
        next:()=>{
          this._callingApi.next(false);
          this.checkSubscription();
        },
        error:(e)=>{
          this._callingApi.next(false);
          console.error(e);
        }
      });
    }).catch((error) => console.error(error));
  };
  private callApiPostSubscription(sub:any) {
    return this.http.post('api/subscribe',{id:this.encounterService.ownerid,subinfo:sub});
  }
  private callApiGetSubscription() {
    return this.http.get(`api/subscribe/${this.encounterService.ownerid}`);
  }
}
