import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-donate-popup',
  templateUrl: './donate-popup.component.html',
  styleUrls: ['./donate-popup.component.scss']
})
export class DonatePopupComponent implements OnInit {
  @Output() close = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  paypalMe(): void {
    const url = `https://paypal.me/codeflayer?country.x=US&locale.x=en_US`;
    window.open(url, '_blank');
    this.handleClose();
  }

  venmoMe(): void {
    const url = `https://account.venmo.com/u/codeflayer`;
    window.open(url, 'blank');
    this.handleClose();
  }
  handleClose(): void {
    this.close.emit();
  }

}
