import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HealthService {
  private readonly _healthy = new BehaviorSubject<boolean>(true);
  readonly healthy$ = this._healthy.asObservable();
  constructor(private http:HttpClient) {
    this.getHealth();
  }

  getHealth() {
    this.http.get('api/health').subscribe({
        next:(data)=>{
          this._healthy.next(true);
        },
        error:(err)=>{
          console.error(err);
          this._healthy.next(false);
        }
      });
  }
}
