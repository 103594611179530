<div class="wrapper">
  <div class="header">
    Encounter: {{encounterService.getEncounterID()}}
  </div>
  <div class="expire">Expires:{{encounterService.getEncounterExpire('yyyy-MM-dd h:mm aaa')}}</div>
  <div class="header">Round: {{(encounterService.encounter$|async)?.round}}</div>
  <div class="header">Characters</div>
  <app-npc-row *ngFor="let character of (characters$|async)
               |hiddenCreatures:(encounterService.encounter$|async)?.ownerid === encounterService.ownerid;index as i"
               [highlight]="character|turn:(encounterService.encounter$|async)"
               [character]="character"
               [showDeadBtn]="true" [showBonus]="true" [showIncapacitatedBtn]="true" [showHiddenBtn]="true"
               [showHp]="(!character.owner && ((encounterService.encounter$|async)?.ownerid === encounterService.ownerid
                           && (encounterService.encounter$|async).dmOptions?.showCreatureHp)
                           || ((encounterService.encounter$|async).dmOptions?.showCreatureHpToPlayers))"
               [showNotes]="(encounterService.encounter$|async)?.ownerid === encounterService.ownerid
                           && (encounterService.encounter$|async).dmOptions?.showDmNotes"
               [showPlayerNotes]="(encounterService.encounter$|async).dmOptions?.showPlayerNotes"
               [showBattleBtn]="(encounterService.encounter$|async)?.ownerid === encounterService.ownerid"
               (doBattle)="openTurnForm($event)"></app-npc-row>
  <div class="buttons">
    <div *ngIf="(encounterService.encounter$|async)?.ownerid === encounterService.ownerid">
      <div class="icon-btn" (tap)="endEncModalShow = true">
        <img class="bouncy" src="assets/icons/cancel.svg" oncontextmenu="return false;">
        <span>End Encounter</span>
      </div>
    </div>
    <div class="divider" *ngIf="(encounterService.encounter$|async)?.ownerid === encounterService.ownerid"></div>
    <div *ngIf="(characters$|async)|playersTurn:(encounterService.encounter$|async):encounterService.ownerid">
      <div class="icon-btn" (tap)="completeTurn()">
        <img class="bouncy" src="assets/icons/ok.svg" oncontextmenu="return false;">
        <span>Complete Turn</span>
      </div>
    </div>
    <div class="divider" *ngIf="(characters$|async)|playersTurn:(encounterService.encounter$|async):encounterService.ownerid"></div>
    <div>
      <div class="icon-btn" (tap)="buzzCurrentCharacter()">
        <img class="bouncy" src="assets/icons/bell.svg" oncontextmenu="return false;">
        <span>Buzz Character</span>
      </div>
    </div>
  </div>
  <!-- TURN FORM -->
  <div class="bd" [ngClass]="{open:mode === 1}">
    <app-run-turn-form (closeFormEvent)="closeTurnForm()"></app-run-turn-form>
  </div>
  <!-- Button Modal -->
  <div class="btn-modal" [ngClass]="{open:endEncModalShow}">
    <div>
      <div class="hold">
        <div class="load" [ngClass]="{fillup:endEncCounter>0}"></div>
        <img alt="End Encounter" src="assets/icons/hand-cursor.svg" oncontextmenu="return false;" (press)="endEncounterStartInterval()" (pressup)="endEncounterStopInterval()">
      </div>
      <span>Hold to End Encounter</span>
      <img class="bouncy" alt="Cancel" class="cancel" src="assets/icons/cancel.svg" oncontextmenu="return false;" (tap)="endEncModalShow = false">
      <span>Cancel</span>
    </div>
  </div>
</div>
