import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-faq-popup',
  templateUrl: './faq-popup.component.html',
  styleUrls: ['./faq-popup.component.scss']
})
export class FaqPopupComponent implements OnInit {
  @Output() close = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  handleClose(){
    this.close.emit();
  }

}
