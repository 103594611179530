<div [formGroup]="turnForm" class="wrapper">
  <div class="header">{{character?.name}}</div>
  <div class="form-title">Turns to be Incapacitated</div>
  <div>
    <app-number-selector formControlName="incapacitated" [min]="0" [max]="999"></app-number-selector>
  </div>
  <div class="form-title">Incapacitate Indefinitely</div>
  <div>
    <label class="switch">
      <input type="checkbox" formControlName="debilitated">
      <span class="slider round"></span>
    </label>
  </div>
  <div class="form-title" *ngIf="!character?.owner || !character?.owner.length">Hidden</div>
  <div *ngIf="!character?.owner || !character?.owner.length">
    <label class="switch">
      <input type="checkbox" formControlName="hidden">
      <span class="slider round"></span>
    </label>
  </div>
  <div class="form-title">Dead</div>
  <div>
    <label class="switch">
      <input type="checkbox" formControlName="dead">
      <span class="slider round"></span>
    </label>
  </div>
  <div *ngIf="(encounterService.encounter$|async).dmOptions.showCreatureHp"
       class="form-title">HP</div>
  <div *ngIf="(encounterService.encounter$|async).dmOptions.showCreatureHp">
    <app-number-selector formControlName="hp" [min]="0" [max]="999"></app-number-selector>
  </div>
  <div *ngIf="(encounterService.encounter$|async).dmOptions.showDmNotes" class="form-title">Private Notes</div>
  <div *ngIf="(encounterService.encounter$|async).dmOptions.showDmNotes">
    <textarea rows="4" maxlength="240" formControlName="notes"></textarea>
  </div>
  <div *ngIf="(encounterService.encounter$|async).dmOptions.showPlayerNotes" class="form-title">Player Visible Notes</div>
  <div *ngIf="(encounterService.encounter$|async).dmOptions.showPlayerNotes">
    <textarea rows="4" maxlength="240" formControlName="playerNotes"></textarea>
  </div>
  <div class="buttons">
    <div>
      <div class="icon-btn" (tap)="submit()" >
        <img class="bouncy" alt="Update" src="assets/icons/plus.svg" oncontextmenu="return false;">
        <span>Update</span>
      </div>
    </div>
    <div class="divider"></div>
    <div>
      <div class="icon-btn" (tap)="cancel()">
        <img class="bouncy" alt="Cancel" src="assets/icons/cancel.svg" oncontextmenu="return false;">
        <span>Cancel</span>
      </div>
    </div>
  </div>
</div>
