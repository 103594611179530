import { Pipe, PipeTransform } from '@angular/core';
import {NpcIcons} from "./npc-icons";

@Pipe({
  name: 'iconSrc'
})
export class IconSrcPipe implements PipeTransform {
  npcIcons = new NpcIcons();
  transform(value: any): string {
    if(typeof value === 'number'){
      return this.npcIcons.icons[value as number].path;
    } else if(typeof value === 'string' && value.length){
      return value as string;
    } else {
      return 'assets/icons/question-black.svg';
    }
  }

}
