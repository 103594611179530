<div [formGroup]="npcForm" class="wrapper">
  <div class="header">{{npcForm.controls['id'].getRawValue()!==''?'Edit':'Add'}} Creature</div>
  <div class="form-title">Name</div>
  <div class="form-wrapper">
    <div class="selected-icon">
      <div>
        <img [src]="icons.icons[npcForm.controls['icon'].getRawValue()].path">
      </div>
    </div>
    <input type="text" maxlength="20" (keyup.enter)="creatureNameField.blur()"
           [placeholder]="npcForm.controls['name'].invalid && (npcForm.controls['name'].dirty || npcForm.controls['name'].touched)?'Name Required':'Add Name'"
           [ngClass]="{invalid:npcForm.controls['name'].invalid && (npcForm.controls['name'].dirty || npcForm.controls['name'].touched)}"
           #creatureNameField (tap)="handleTextHighlight()" formControlName="name"></div>
  <div class="form-title">Select Icon</div>
  <div class="icon-scroller">
    <div class="icon-scroller-inner">
      <div *ngFor="let icon of icons.icons;index as i;" (tap)="selectIcon(i)"><img class="bouncy" [src]="icon.path"></div>
    </div>
  </div>
  <div class="form-title">Hidden</div>
  <div>
    <label class="switch">
      <input type="checkbox" formControlName="hidden">
      <span class="slider round"></span>
    </label>
  </div>
  <div class="form-title">Initiative Bonus %</div>
  <div>
    <app-number-selector formControlName="initBonus" [min]="0" [max]="100"></app-number-selector>
  </div>
  <div *ngIf="(encounterService.localDmOptions$|async).showCreatureHp" class="form-title">HP</div>
  <div *ngIf="(encounterService.localDmOptions$|async).showCreatureHp">
    <app-number-selector formControlName="hp" [min]="0" [max]="999"></app-number-selector>
  </div>
  <div *ngIf="(encounterService.localDmOptions$|async).showDmNotes" class="form-title">Private Notes</div>
  <div *ngIf="(encounterService.localDmOptions$|async).showDmNotes">
    <textarea rows="4" maxlength="240" formControlName="notes"></textarea>
  </div>
  <div *ngIf="(encounterService.localDmOptions$|async).showPlayerNotes" class="form-title">Player Visible Notes</div>
  <div *ngIf="(encounterService.localDmOptions$|async).showPlayerNotes">
    <textarea rows="4" maxlength="240" formControlName="playerNotes"></textarea>
  </div>
  <div class="buttons">
    <div>
      <div class="icon-btn" (tap)="addCreature()" >
        <img class="bouncy" alt="Add Creature" src="assets/icons/plus.svg" oncontextmenu="return false;">
        <span>{{npcForm.controls['id'].getRawValue()!==''?'Edit':'Add'}} Creature</span>
      </div>
    </div>
    <div class="divider"></div>
    <div>
      <div class="icon-btn" (tap)="cancelAddCreature()">
        <img class="bouncy" alt="Cancel" src="assets/icons/cancel.svg" oncontextmenu="return false;">
        <span>Cancel</span>
      </div>
    </div>
  </div>
</div>
