<div class="wrapper">
  <div class="header">
    Join Encounter: {{encounterService.getEncounterID()}}
  </div>
  <div class="expire">Expires:{{encounterService.getEncounterExpire('yyyy-MM-dd h:mm aaa')}}</div>
  <div class="header">Locally Stored Players</div>
  <app-npc-row *ngFor="let local of localPlayers"
               [character]="local"
               [showBonus]="true" [showDeleteBtn]="true"
               [showAddBtn]="!(isLocalPlayerInPlayerList(local)|async)"
               (doAdd)="addLocalPlayer($event)"
               (doDelete)="deleteLocalPlayer($event)"></app-npc-row>
  <div class="expire" *ngIf="!localPlayers.length">No Local Players have been added yet.</div>
  <div class="header">Players</div>
  <app-npc-row *ngFor="let player of encounter.players"
               [character]="player"
               [showBonus]="true"
               [showDeleteBtn]="player.owner === encounterService.ownerid"
               [showEditBtn]="player.owner === encounterService.ownerid"
               (doDelete)="deletePlayer($event)"
               (doEdit)="openAddPlayerForm($event)"></app-npc-row>
  <div class="expire" *ngIf="!encounter.players?.length">No Players have been added to the encounter yet.</div>
  <div class="header">Creatures</div>
  <app-npc-row *ngFor="let npc of encounter.npcs|hiddenCreatures"
               [character]="npc"></app-npc-row>
  <div class="expire" *ngIf="!encounter.npcs?.length">No Creatures have been added to the encounter yet.</div>
  <div class="buttons">
    <div>
      <div class="icon-btn" (tap)="openAddPlayerForm()">
        <img class="bouncy" src="assets/icons/battle.svg" oncontextmenu="return false;">
        <span>Add Player</span>
      </div>
    </div>
  </div>
  <!-- ADD Player -->
  <div class="bd" [ngClass]="{open:mode === 1}">
    <app-add-player-form (closeFormEvent)="closeAddPlayer()"></app-add-player-form>
  </div>
</div>
