import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Character} from "../../Classes/character";
import {EncounterService} from "../../Services/encounter.service";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-npc-row',
  templateUrl: './npc-row.component.html',
  styleUrls: ['./npc-row.component.scss']
})
export class NpcRowComponent implements OnInit, OnDestroy{
  @Input() highlight = false;
  @Input() character:Character|undefined;
  @Input() showBonus = false;
  @Input() showHp = false;
  @Input() showNotes = false;
  @Input() showPlayerNotes = false;
  @Input() showAddBtn = false;
  @Input() showEditBtn = false;
  @Input() showDeleteBtn = false;
  @Input() showBattleBtn = false;
  @Input() showHiddenBtn = false;
  @Input() showIncapacitatedBtn = false;
  @Input() showDeadBtn = false;
  @Output() doAdd = new EventEmitter<Character>();
  @Output() doEdit = new EventEmitter<Character>();
  @Output() doDelete = new EventEmitter<Character>();
  @Output() doBattle = new EventEmitter<Character>();
  deleteTapped = false;
  deleteTapTimeout:any;
  deleteInterval:any;
  deleteCounter:number = 0;
  callingApi:boolean = false;
  destroy$:Subject<boolean> = new Subject();

  constructor(public encounterService:EncounterService) { }

  ngOnInit(): void {
    this.encounterService.callingApi$.pipe(takeUntil(this.destroy$)).subscribe(this.updateCallingApi);
  }

  updateCallingApi(val:boolean){
    this.callingApi = val;
  }

  deleteTap(){
    if(this.callingApi) return;
    this.deleteTapped = true;
    this.deleteTapTimeout = setTimeout(()=>{
      this.deleteTapped = false;
    },2000)
  }

  startDeleteInterval(){
    if(this.callingApi) return;
    const self = this;
    this.deleteInterval = setInterval(async ()=>{
      self.deleteCounter = self.deleteCounter + 1;
      if(self.deleteCounter >= 70) {
        await self.handleDelete();
      }
    },50);
  }

  handleDelete = ()=> {
    this.stopDeleteInterval();
    this.doDelete.emit(this.character);
  }

  stopDeleteInterval = ()=> {
    this.deleteCounter = 0;
    clearInterval(this.deleteInterval);
  }

  handleAdd(){
    if(this.callingApi) return;
    this.doAdd.emit(this.character);
  }

  handleEdit(){
    if(this.callingApi) return;
    this.doEdit.emit(this.character);
  }

  handleBattle(){
    if(this.callingApi) return;
    this.doBattle.emit(this.character);
  }

  ngOnDestroy(): void {
    clearTimeout(this.deleteTapTimeout);
    clearInterval(this.deleteInterval);
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
