import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {EncounterService, uimode} from "../../Services/encounter.service";
import {Subject} from "rxjs";
import {DmOptions} from "../../Classes/dm-options";

@Component({
  selector: 'app-edit-local-dm-options',
  templateUrl: './edit-local-dm-options.component.html',
  styleUrls: ['./edit-local-dm-options.component.scss']
})
export class EditLocalDmOptionsComponent implements OnInit, OnDestroy {
  destroy$:Subject<boolean> = new Subject();
  dmOptionsForm:FormGroup = this.fb.group(
    {
      showDmNotes:[false],
      showPlayerNotes:[false],
      showCreatureHp:[false],
      showPlayerHp:[false],
      showCreatureHpToPlayers:[false]
    }
  );
  constructor(public encounterService:EncounterService, public fb:FormBuilder) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    const opts = this.encounterService.getLocalDmOptions();
    if(opts){
      this.dmOptionsForm.reset({
        showDmNotes:opts.showDmNotes,
        showPlayerNotes:opts.showPlayerNotes,
        showCreatureHp:opts.showCreatureHp,
        showPlayerHp:opts.showPlayerHp,
        showCreatureHpToPlayers:opts.showCreatureHpToPlayers
      });
    }
  }

  handleShowCreatureHpChange(event:any){
    if(!event.currentTarget.checked){
      this.dmOptionsForm.patchValue({'showCreatureHpToPlayers':false});
    }
  }

  handleShowCreatureHpToPlayersChange(event:any){
    if(event.currentTarget.checked){
      this.dmOptionsForm.patchValue({'showCreatureHp':true});
    }
  }

  submit(){
    this.encounterService.setLocalDmOptions(this.dmOptionsForm.getRawValue() as DmOptions);
    this.encounterService.setMode(uimode.LOAD);
  }

  cancel(){
    this.encounterService.setMode(uimode.LOAD);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
