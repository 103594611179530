import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {NPC} from "../../Classes/npc";
import {NpcIcons} from "../../Classes/npc-icons";
import {EncounterService} from "../../Services/encounter.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-add-creature-form',
  templateUrl: './add-creature-form.component.html',
  styleUrls: ['./add-creature-form.component.scss']
})
export class AddCreatureFormComponent implements OnInit {
  @Output() closeFormEvent = new EventEmitter<void>();
  @ViewChild('creatureNameField') creatureNameField:ElementRef;
  icons = new NpcIcons();

  npcForm:FormGroup = this.fb.group(
    {
      id:[''],
      name:['',Validators.required],
      icon:[0],
      hidden:[false],
      initBonus:[0],
      hp:[0],
      notes:[''],
      playerNotes:['']
    }
  );

  constructor(public encounterService:EncounterService, public fb:FormBuilder) { }

  ngOnInit(): void {}

  async addCreature(){
    if(this.npcForm.status === 'VALID'){
      const npc = this.npcForm.getRawValue() as NPC;
      await this.encounterService.addNpc(npc);
      this.closeFormEvent.emit();
    } else {
      this.npcForm.markAllAsTouched();
    }
  }

  cancelAddCreature(){
    this.closeFormEvent.emit();
  }

  handleTextHighlight(){
    const native = this.creatureNameField.nativeElement;
    if(native.selectionStart - native.selectionEnd){
      native.removeAllRanges();
    } else {
      native.select();
    }
  }

  selectIcon(index:number): void {
    this.npcForm.patchValue({icon: index});
  }

}
