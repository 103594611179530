import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NpcIcons} from "../../Classes/npc-icons";
import {EncounterService} from "../../Services/encounter.service";
import {BehaviorSubject, Subject, takeUntil} from "rxjs";
import {Encounter} from "../../Classes/encounter";
import {Character} from "../../Classes/character";
import {RunTurnFormComponent} from "../run-turn-form/run-turn-form.component";

@Component({
  selector: 'app-run-encounter',
  templateUrl: './run-encounter.component.html',
  styleUrls: ['./run-encounter.component.scss']
})
export class RunEncounterComponent implements OnInit, OnDestroy {
  destroy$:Subject<boolean> = new Subject();
  icons = new NpcIcons();
  private readonly _characters = new BehaviorSubject<Character[]>([]);
  readonly characters$ = this._characters.asObservable();
  mode = runMode.BASE;
  endEncModalShow = false;
  endEncCounter = 0;
  endEncInterval:any;
  @ViewChild(RunTurnFormComponent) runTurnFormComponent:RunTurnFormComponent;
  constructor(public encounterService:EncounterService) { }

  ngOnInit(): void {
    this.encounterService.encounter$.pipe(takeUntil(this.destroy$)).subscribe(this.encounterUpdated);
  }

  encounterUpdated = (enc:Encounter)=> {
    if(!enc) return;
    const retval = [].concat(enc.players,enc.npcs).sort((a,b)=>{
      if(enc.turnOrder.indexOf(a.id) < enc.turnOrder.indexOf(b.id)){return -1}
      else if(enc.turnOrder.indexOf(a.id) > enc.turnOrder.indexOf(b.id)){return 1}
      return 0;
    }) as Character[];
    this._characters.next(retval);
  }

  endEncounterStartInterval(){
    const self = this;
    self.endEncInterval = setInterval(()=>{
      self.endEncCounter = self.endEncCounter + 1;
      if(self.endEncCounter >= 60) {
        self.endEncModalShow = false;
        self.endEncounterStopInterval();
        self.endEncounter().then();

      }
    },50);
  }
  endEncounterStopInterval(){
    this.endEncCounter = 0;
    clearInterval(this.endEncInterval);
  }
  async endEncounter(){
    await this.encounterService.endEncounter();
  }

  openTurnForm(character:Character){
    this.runTurnFormComponent.character = character;
    this.runTurnFormComponent.turnForm.reset({
      incapacitated:character.incapacitated?character.incapacitated:0,
      debilitated:character.debilitated?character.debilitated:false,
      dead:character.dead?character.dead:false,
      hidden:character.hidden?character.hidden:false,
      hp:character.hp?character.hp:0,
      notes:character.notes?character.notes:'',
      playerNotes:character.playerNotes?character.playerNotes:''

    });
    this.mode = runMode.EDIT_TURN;
  }

  async closeTurnForm(){
    this.mode = runMode.BASE;
    await this.encounterService.updateEncounter();
  }
  async completeTurn(){
    await this.encounterService.completeTurn();
  }

  async buzzCurrentCharacter(){
    await this.encounterService.buzzCharacter();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    clearInterval(this.endEncInterval);
  }
}
export enum runMode {
  BASE,
  EDIT_TURN
}
