import { Pipe, PipeTransform } from '@angular/core';
import {Character} from "./character";
import {Encounter} from "./encounter";

@Pipe({
  name: 'playersTurn'
})
export class PlayersTurnPipe implements PipeTransform {

  transform(value: Character[], encounter:Encounter,owner:string): boolean {
    if(value && encounter && owner) {
      if(encounter.ownerid === owner) return true;
      return value[encounter.turnIndex].owner === owner;
    } else {
      return false;
    }
  }

}
