import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.scss']
})
export class NotificationPopupComponent implements OnInit {
  @Output() allow = new EventEmitter();
  @Output() deny = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  handleAllow() {
    this.allow.emit();
  }
  handleDeny() {
    this.deny.emit();
  }

}
