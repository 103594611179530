<div class="npc-row" [ngClass]="{'delete':deleteCounter>0,turn:highlight}">
  <div class="deleteTap" [ngClass]="{show:deleteTapped}"><img alt="hold" src="assets/icons/hand-cursor.svg"> Press and hold to delete</div>
  <div class="avatar"><img [src]="character.icon|iconSrc"></div>
  <div class="info">
    <div class="name">{{character?.name}}</div>
    <div class="bonus">
      {{showBonus ? 'Bonus: '+character?.initBonus+'%' : ''}}{{(showHp && character?.hp !== undefined) ? ', HP: '+character?.hp:''}}</div>
  </div>
  <div *ngIf="showHiddenBtn && character.hidden"><img alt="Hidden" src="assets/icons/hide.svg"></div>
  <div *ngIf="showIncapacitatedBtn && (character.incapacitated || character.debilitated)>0"><img alt="Incapacitated" src="assets/icons/incapacitated-white.svg"></div>
  <div *ngIf="showDeadBtn && character.dead"><img alt="Dead" src="assets/icons/dead-white.svg"></div>
  <div *ngIf="showBattleBtn" (tap)="handleBattle()"><img [ngClass]="{'img-disabled':encounterService.callingApi$|async}" class="bouncy" alt="Edit Turn" src="assets/icons/battle.svg"></div>
  <div *ngIf="showAddBtn" (tap)="handleAdd()"><img [ngClass]="{'img-disabled':encounterService.callingApi$|async}" class="bouncy" alt="Add" src="assets/icons/plus.svg"></div>
  <div *ngIf="showEditBtn" (tap)="handleEdit()"><img [ngClass]="{'img-disabled':encounterService.callingApi$|async}" class="bouncy" alt="Edit" src="assets/icons/edit.svg"></div>
  <div *ngIf="showDeleteBtn" (tap)="deleteTap()"
       (pressup)="stopDeleteInterval()"
       (press)="startDeleteInterval()">
    <img alt="Remove" src="assets/icons/cancel.svg" [ngClass]="{'img-disabled':encounterService.callingApi$|async}" oncontextmenu="return false;">
  </div>
  <div class="break"></div>
  <div class="notes" *ngIf="showNotes || showPlayerNotes">
    <span *ngIf="showNotes">{{character.notes}}</span>
    <span *ngIf="showPlayerNotes">{{character.playerNotes}}</span>
  </div>
</div>
