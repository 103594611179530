import {Component, OnDestroy, OnInit} from '@angular/core';
import {EncounterService, uimode} from "../../Services/encounter.service";
import {firstValueFrom, Subject} from "rxjs";

@Component({
  selector: 'app-encounter-loader',
  templateUrl: './encounter-loader.component.html',
  styleUrls: ['./encounter-loader.component.scss']
})

export class EncounterLoaderComponent implements OnInit, OnDestroy {

  encounterCodeValue:string = "";
  encounterCodeSubmit:string = "";
  destroy$:Subject<boolean> = new Subject();

  constructor(public encounterService:EncounterService) {
  }

  get encounterCode() {
    return this.encounterCodeValue;
  }
  set encounterCode(val) {
    this.encounterCodeValue = val.toUpperCase();
    if(this.encounterCodeValue.length === 4) {
      this.encounterCodeSubmit = this.encounterCodeValue;
      this.encounterService.getEncounter(this.encounterCodeSubmit).then(()=>{
        this.encounterCodeValue = '';
      });
    }
  }

  ngOnInit(): void {}

  async generateEncounter() {
    await this.encounterService.createEncounter();
  }

  async editLocalPlayers(){
    this.encounterService.setMode(uimode.LOCAL);
  }

  async editDmOptions() {
    this.encounterService.setMode(uimode.DM_OPTIONS);
  }

  async reloadEncounter():Promise<void> {
    let e = await firstValueFrom(this.encounterService.encounter$);
    this.encounterService.getEncounter(e.id).then(()=>{
      this.encounterCodeValue = '';
    })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
