import {Injectable, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {HttpClientModule} from "@angular/common/http";
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {HammerTimeDirective} from "./HammerTimeDirective";
// Hammer
// import * as Hammer from 'hammerjs';
import {HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
// import * as HammerTouch from 'hammer-touchemulator';

import {FormsModule, ReactiveFormsModule} from "@angular/forms";

import { EncounterLoaderComponent } from './Components/encounter-loader/encounter-loader.component';
import { JoinEncounterComponent } from './Components/join-encounter/join-encounter.component';
import { EditEncounterComponent } from './Components/edit-encounter/edit-encounter.component';
import { AddCreatureFormComponent } from './Components/add-creature-form/add-creature-form.component';
import { AddPlayerFormComponent } from './Components/add-player-form/add-player-form.component';
import { HiddenCreaturesPipe } from './Classes/hidden-creatures.pipe';
import { RunEncounterComponent } from './Components/run-encounter/run-encounter.component';
import { IconSrcPipe } from './Classes/icon-src.pipe';
import { PlayersTurnPipe } from './Classes/players-turn.pipe';
import { RunTurnFormComponent } from './Components/run-turn-form/run-turn-form.component';
import { TurnPipe } from './Classes/turn.pipe';
import { NumberSelectorComponent } from './Components/number-selector/number-selector.component';
import { FaqPopupComponent } from './Components/faq-popup/faq-popup.component';
import { NotificationPopupComponent } from './Components/notification-popup/notification-popup.component';
import { DonatePopupComponent } from './Components/donate-popup/donate-popup.component';
import { NpcRowComponent } from './Components/npc-row/npc-row.component';
import { EditLocalPlayersComponent } from './Components/edit-local-players/edit-local-players.component';
import { NoterComponent } from './Components/noter/noter.component';
import { EditLocalDmOptionsComponent } from './Components/edit-local-dm-options/edit-local-dm-options.component';

// HammerTouch();
(window as any).preventContext = false;
window.oncontextmenu = (event) => {
  if ((window as any).preventContext) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    return false;
  }
  return true;
};
window.addEventListener('load', function() {
  window.history.pushState({}, '')
})

window.addEventListener('popstate', function() {
  window.history.pushState({}, '')
})

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  override = ({
    swipe: { enabled: false },
    pinch: { enable: false },
    rotate: {enable: false }
  } as any);
}

@NgModule({
  declarations: [
    AppComponent,
    HammerTimeDirective,
    EncounterLoaderComponent,
    JoinEncounterComponent,
    EditEncounterComponent,
    AddCreatureFormComponent,
    AddPlayerFormComponent,
    HiddenCreaturesPipe,
    RunEncounterComponent,
    IconSrcPipe,
    PlayersTurnPipe,
    RunTurnFormComponent,
    TurnPipe,
    NumberSelectorComponent,
    FaqPopupComponent,
    NotificationPopupComponent,
    DonatePopupComponent,
    NpcRowComponent,
    EditLocalPlayersComponent,
    NoterComponent,
    EditLocalDmOptionsComponent,
  ],
  imports: [
    BrowserModule,
    HammerModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
