<div class="wrapper">
  <div class="header">
    <img src="assets/icons/icon-32x32.png" alt="cool ass dragon">
    Initiative Tracker
  </div>
  <app-noter></app-noter>
  <app-encounter-loader *ngIf="healthService.healthy$|async"></app-encounter-loader>
  <div class="additional-buttons" *ngIf="healthService.healthy$|async">
    <button (tap)="showFAQ = true">What is this?</button>
    <button (tap)="showDonate = true">Buy me a coffee!</button>
  </div>
  <div class="slider-panel-left" [ngClass]="{open:(encounterService.mode$|async)===3}">
    <div class="header">
      <div class="btn" (tap)="goBack()"><img alt="Back" src="assets/icons/arrow-left.svg" style="height:24px;"></div>
      {{(encounterService.mode$|async) === 1?'Manage Encounter':'Encounter'}}
    </div>
    <app-run-encounter *ngIf="(encounterService.mode$|async) === 3"></app-run-encounter>
  </div>
  <div class="slider-panel-right" [ngClass]="{open:(encounterService.mode$|async)===1 || (encounterService.mode$|async)===2 || (encounterService.mode$|async)===4 || (encounterService.mode$|async)===5}">
    <div class="header">
      <div class="btn" (tap)="goBack()"><img alt="Back" src="assets/icons/arrow-left.svg" style="height:24px;"></div>
      {{(encounterService.mode$|async) === 1?'Manage Encounter':'Encounter'}}
    </div>
    <app-edit-encounter *ngIf="(encounterService.mode$|async) === 1"></app-edit-encounter>
    <app-join-encounter *ngIf="(encounterService.mode$|async) === 2"></app-join-encounter>
    <app-edit-local-players *ngIf="(encounterService.mode$|async) === 4"></app-edit-local-players>
    <app-edit-local-dm-options *ngIf="(encounterService.mode$|async)===5"></app-edit-local-dm-options>
  </div>
  <div class="notification-modal" [ngClass]="{show:!(subscribeService.notificationsEnabled$|async) && !modalDismissed}">
    <app-notification-popup (allow)="requestNotifications()" (deny)="modalDismissed=true"></app-notification-popup>
  </div>
  <div class="notification-modal" [ngClass]="{show:showDonate}">
    <app-donate-popup (close)="showDonate = false"></app-donate-popup>
  </div>
  <div class="notification-modal" [ngClass]="{show:showFAQ}">
    <app-faq-popup (close)="showFAQ = false"></app-faq-popup>
  </div>
  <div class="error" *ngIf="!(healthService.healthy$|async)">
    The server is currently unreachable. Please try again later.
  </div>
  <div class="footer">
    <div>Copyright &copy; 2023 Codeflayer - All Rights Reserved </div>
    <div>Version: 2023.8.13.0</div>
    <div>Icons by <span class="link" (tap)="link('https://icons8.com')">Icons8</span> and <span class="link" (tap)="link('https://game-icons.net/tags/creature.html')">game-icons.net</span></div>
    <div><span class="link" (tap)="link('tos.html')">Terms of Service</span></div>
    <div><span class="link" (tap)="link('privacy.html')">Privacy Policy</span></div>
  </div>
  <div class="animated-background" [ngClass]="{loading:(encounterService.callingApi$|async)||(subscribeService.callingApi$|async)}"></div>
</div>
