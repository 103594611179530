import {Component, Input, OnDestroy} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'app-number-selector',
  templateUrl: './number-selector.component.html',
  styleUrls: ['./number-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: NumberSelectorComponent
    }
  ]
})
export class NumberSelectorComponent implements ControlValueAccessor, OnDestroy {
  num:number = 0;
  disabled: boolean;
  decrementInterval:any;
  incrementInterval:any;
  onChange: (value: number) => void = () => {};
  onTouched: () => void = () => {};
  @Input() min:number = 0;
  @Input() max:number = 100;
  constructor() {}

  increment(){
    this.writeValue(++this.num);
  }
  incrementPress(){
    const self = this;
    this.incrementInterval = setInterval(()=>{self.increment()},100);
  }
  incrementPressUp(){
    clearInterval(this.incrementInterval);
  }
  decrement(){
    this.writeValue(--this.num);
  }
  decrementPress(){
    const self = this;
    this.decrementInterval = setInterval(()=>{self.decrement()},100);
  }
  decrementPressUp(){
    clearInterval(this.decrementInterval);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  writeValue(num: number): void {
    if(num>this.max)num = this.max;
    if(num<this.min)num = this.min;
    this.num = num;
    this.onChange(this.num);
  }
  ngOnDestroy() {
    clearInterval(this.incrementInterval);
    clearInterval(this.decrementInterval);
  }

}
