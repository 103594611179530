import * as ls from 'local-storage';
import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Encounter} from "../Classes/encounter";
import {BehaviorSubject} from "rxjs";
import {v4} from 'uuid';
import {NPC} from "../Classes/npc";
import {format} from "date-fns";
import {Player} from "../Classes/player";
import {Socket, io} from "socket.io-client";
import {SwPush} from "@angular/service-worker";
import {Noter} from "../Classes/noter";
import {DmOptions} from "../Classes/dm-options";

export enum uimode {
  LOAD,
  EDIT,
  JOIN,
  RUN,
  LOCAL,
  DM_OPTIONS
}

@Injectable({
  providedIn: 'root'
})
export class EncounterService {

  private readonly _encounter = new BehaviorSubject<Encounter>(null);
  readonly encounter$ = this._encounter.asObservable();
  private readonly _callingApi = new BehaviorSubject<boolean>(false);
  readonly callingApi$ = this._callingApi.asObservable();
  private readonly _createEncounterError = new BehaviorSubject<string>('');
  readonly createEncounterError$ = this._createEncounterError.asObservable();
  private readonly _getEncounterError = new BehaviorSubject<string>('');
  readonly getEncounterError$ = this._getEncounterError.asObservable();
  private readonly _mode = new BehaviorSubject<uimode>(uimode.LOAD);
  readonly mode$ = this._mode.asObservable();
  private readonly _localPlayers = new BehaviorSubject<Player[]>([]);
  readonly localPlayers$ = this._localPlayers.asObservable();
  private readonly _noter = new BehaviorSubject<Noter|null>(null);
  readonly noter$ = this._noter.asObservable();
  private readonly _localDmOptions = new BehaviorSubject<DmOptions|null>(null);
  readonly localDmOptions$ = this._localDmOptions.asObservable();
  public ownerid:string = '';
  private noters:Noter[]=[];
  noterTimeout:any;
  noterTimeout2:any;
  io:Socket|undefined;

  constructor(private http:HttpClient, public swPush:SwPush) {
    if(ls.get('ownerid')) {
      this.ownerid = ls.get('ownerid');
    } else {
      this.ownerid = v4();
      ls.set('ownerid',this.ownerid);
    }
    this.getLocalStoragePlayers();
    this.getLocalDmOptions();
    this.startWebSocket();
  }

  startWebSocket(){
    this.io = io({extraHeaders:{'ownerid':this.ownerid}});
    this.io.on('connected',()=>{
      if(this._encounter.getValue() && this._encounter.getValue().id) {
        this.io.emit('join-encounter',this._encounter.getValue().id);
      }
    });
    this.io.on('encounter-updated',(encounter:Encounter)=>{
      if(this._encounter.getValue().id === encounter.id){
        this._encounter.next(encounter);
        this.setMode();
        if(this._mode.getValue() === uimode.RUN) {
          const turn = [].concat(encounter.npcs,encounter.players).find(c=>c.id === encounter.turnOrder[encounter.turnIndex]);
          const name = (turn.name.endsWith('s') || turn.name.endsWith('S')) ? `${turn.name}'`:`${turn.name}'s`;
          this.addNoter(turn.icon,`It's ${name} turn!`);
        }
      }
    });
    this.io.on('buzzed',()=>{
      const encounter = this._encounter.getValue();
      const ch = [].concat(encounter.npcs,encounter.players).find((d)=>{
        return d.id === encounter.turnOrder[encounter.turnIndex];});
      if((!ch.owner && encounter.ownerid === this.ownerid) || ch.owner === this.ownerid) {
        if(navigator.vibrate){
          navigator.vibrate([50, 50]);
        }
        this.addNoter('assets/icons/dead-black.png', `Take your turn!`);
      }
    });
    this.io.on('encounter-ended',()=>{
      this.cleanUpEncounter();
    });
  }

  getEncounterID():string {
    return this._encounter.getValue().id;
  }

  getEncounterExpire(fmt:string):string{
    return format(new Date(this._encounter.getValue().expireTime), fmt);
  }

  setMode(mode?:uimode){
    if(mode === uimode.DM_OPTIONS ||  mode === uimode.LOCAL || mode === uimode.LOAD) this._mode.next(mode);
    else if(this._encounter.getValue()) { //base mode off of Encounter
      const e = this._encounter.getValue();
      if(e.turnIndex<0){
        this._mode.next(e.ownerid === this.ownerid ? uimode.EDIT : uimode.JOIN);
      } else {
        this._mode.next(uimode.RUN);
      }
    }
  }

  getLocalStorageEncounter():Promise<void>{
    return new Promise((resolve,reject)=>{
      const last:string|undefined = ls.get('last-encounter');
      console.log('last',last);
      if(last && last.length===4) {
        const id: string = last;
        this._callingApi.next(true);
        this._getEncounterError.next('');
        this.callApiGetEncounter(id).subscribe({
          next:data=>{
            this._callingApi.next(false);
            let e = data as Encounter;
            this._encounter.next(e);
            this.io.emit('join-encounter',this._encounter.getValue().id);
            this.setLocalStorageEncounter(this._encounter.getValue().id);
            this.setMode();
            resolve();
          },
          error:err=>{
            console.error(err);
            this._callingApi.next(false);
            ls.remove('last-encounter');
            resolve();
          }
        });
      } else {
        resolve();
      }
    });
  }

  setLocalStorageEncounter(id:string){
    if(id && id.length===4){
      ls.set('last-encounter',id);
    }
  }

  getEncounter(id:string):Promise<void> {
    return new Promise((resolve, reject)=>{
      this._callingApi.next(true);
      this._getEncounterError.next('');
      this.callApiGetEncounter(id).subscribe({
        next:data => {
          this._callingApi.next(false);
          let e = data as Encounter;
          this._encounter.next(e);
          this.io.emit('join-encounter',this._encounter.getValue().id);
          this.setLocalStorageEncounter(this._encounter.getValue().id);
          this.setMode();
          resolve();
        },
        error:err => {
          this._callingApi.next(false);
          if(err.status === 404) {
            this._getEncounterError.next(`Could not find encounter with ID ${id}`);
          } else {
            this._getEncounterError.next(`An error occurred getting the encounter`);
          }
          resolve();
        }
      });
    });
  }

  createEncounter():Promise<void> {
    return new Promise((resolve)=>{
      this._callingApi.next(true);
      this.createEncounterClearError();
      this.callApiCreateEncounter().subscribe({
        next:data => {
          this._callingApi.next(false);
          this._encounter.next(data as Encounter);
          this.io.emit('join-encounter',this._encounter.getValue().id);
          this.setLocalStorageEncounter(this._encounter.getValue().id);
          this.setMode();
          resolve();
        },
        error:err => {
          if(err.status === 429){
            this._createEncounterError.next('Too many requests to create encounters. Try again later');
          } else {
            this._createEncounterError.next('An error occurred creating a new encounter.');
          }
          this._callingApi.next(false);
          console.error(err.error);
          resolve();
        }
      });
    });
  }

  createEncounterClearError() {
    this._createEncounterError.next('');
  }

  async addNpc(npc:NPC) {
    if(npc.id === ''){
      npc.id = v4(); //generate ID for new NPC
      this._encounter.getValue().npcs.push(npc);
    } else {
      const index = this._encounter.getValue().npcs.findIndex(e=> e.id === npc.id);
      this._encounter.getValue().npcs[index] = npc;
    }
    await this.updateEncounter();
  }

  async addPlayer(player:Player) {
    player.owner = this.ownerid;
    if(player.id === '') {
      player.id = v4(); //generate ID for new NPC
    }
    const index = this._encounter.getValue().players.findIndex(e=> e.id === player.id);
    if(index<0){
      this._encounter.getValue().players.push(player);
    } else {
      this._encounter.getValue().players[index] = player;
    }
    this.addLocalStoragePlayer(player);
    await this.updateEncounter();
  }

  getLocalStoragePlayers(){
    if(!ls.get('local-players')){
      ls.set('local-players',JSON.stringify([]));
    }
    this._localPlayers.next(JSON.parse(ls.get('local-players')) as Player[]);
  }

  addLocalStoragePlayer(player:Player){
    const index = this._localPlayers.getValue().findIndex((p)=>{return p.id === player.id});
    if(index<0){
      this._localPlayers.getValue().push(player);
    } else {
      this._localPlayers.getValue()[index]=player;
    }
    ls.set('local-players',JSON.stringify(this._localPlayers.getValue()));
  }

  deleteLocalStoragePlayer(player:Player){
    const index = this._localPlayers.getValue().findIndex((p)=>{return p.id === player.id});
    if(index>-1) {
      this._localPlayers.getValue().splice(index,1);
    }
    ls.set('local-players',JSON.stringify(this._localPlayers.getValue()));
  }

  async startEncounter(){
    this._callingApi.next(true);
    this.callApiStartEncounter().subscribe({
      next:d=>{
        this._callingApi.next(false);
        this._encounter.next(d as Encounter);
      },
      error:err=>{
        console.error(err);
        this._callingApi.next(false);
      }
    });
  }

  async completeTurn(){
    this._callingApi.next(true);
    this.callApiTurn().subscribe({
      next:d=>{
        this._callingApi.next(false);
        this._encounter.next(d as Encounter);
      },
      error:err=>{
        console.error(err);
        this._callingApi.next(false);
      }
    });
  }

  async endEncounter(){
    this._callingApi.next(true);
    this.callApiEndEncounter().subscribe({
      next:d=>{
        this._callingApi.next(false);
        this.cleanUpEncounter();
      },
      error:err=>{
        console.error(err);
        this._callingApi.next(false);
      }
    });
  }

  async buzzCharacter(){
    this.io.emit('buzz-character',this._encounter.getValue().id)
  }

  cleanUpEncounter(){
    ls.remove('last-encounter');
    this._encounter.next(undefined);
    this._mode.next(uimode.LOAD);
  }

  async deleteNpc(npc:NPC){
    if(!npc) return;
    const index = this._encounter.getValue().npcs.findIndex(d=>{
      return d.id === npc.id
    });
    this._encounter.getValue().npcs.splice(index,1);
    await this.updateEncounter();
  }

  async deletePlayer(player:Player){
    if(!player) return;
    const index = this._encounter.getValue().players.findIndex(d=>{
      return d.id === player.id
    });
    this._encounter.getValue().players.splice(index,1);
    await this.updateEncounter();
  }

  updateEncounter(nextHandler?:Function, errHandler?:Function):Promise<void>{
    return new Promise((resolve,reject)=>{
      this._callingApi.next(true);
      this.callApiUpdateEncounter().subscribe({
        next:()=> {
          this._callingApi.next(false);
          if(nextHandler){nextHandler()};
          resolve();
        },
        error:(err) => {
          console.error(err);
          this._callingApi.next(false);
          if(errHandler){errHandler()};
          reject();
        }
      });
    });
  }

  addNoter(icon:string, message:string){
    const self = this;
    this.noters.push(new Noter(icon,message));
    if(this.noters.length === 1){
      this._noter.next(this.noters[0]);
      this.noterTimeout = setTimeout(self.popNoter, 6000);
    }
  }

  popNoter = ()=> {
    clearTimeout(this.noterTimeout2);
    clearTimeout(this.noterTimeout);
    const self = this;
    this.noters.shift();
    if(this.noters.length){
      this._noter.next(null);
      this.noterTimeout2 = setTimeout(()=>{self._noter.next(self.noters[0]);},500);
      this.noterTimeout = setTimeout(self.popNoter, 6000);
    } else {
      this._noter.next(null);
    }
  }

  getLocalDmOptions():DmOptions {
    if(!ls.get('dm-options')){
      ls.set('dm-options',JSON.stringify(new DmOptions()));
    }
    const dmOpts = JSON.parse(ls.get('dm-options')) as DmOptions;
    this._localDmOptions.next(dmOpts);
    return dmOpts;
  }

  setLocalDmOptions(dmOptions:DmOptions){
    ls.set('dm-options', JSON.stringify(dmOptions));
    if(this._encounter.getValue()){
      this._encounter.getValue().dmOptions = dmOptions;
    }
    this.getLocalDmOptions();
  }

  private callApiGetEncounter(id:string) {
    return this.http.get(`api/encounter/${id}`);
  }

  private callApiCreateEncounter() {
    return this.http.post('api/encounter',{ownerid:this.ownerid, dmOptions:this.getLocalDmOptions()});
  }

  private callApiUpdateEncounter() {
    return this.http.put('api/encounter',this._encounter.getValue());
  }

  private callApiStartEncounter() {
    return this.http.put('api/encounter/start',this._encounter.getValue());
  }

  private callApiTurn() {
    return this.http.put('api/encounter/turn',this._encounter.getValue());
  }
  private callApiEndEncounter(){
    return this.http.put('api/encounter/end',{ownerid:this.ownerid,encounter:this._encounter.getValue()});
  }
}
