import {Component, OnDestroy, OnInit, SecurityContext, ViewChild} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {editMode} from "../edit-encounter/edit-encounter.component";
import {NpcIcons} from "../../Classes/npc-icons";
import {Encounter} from "../../Classes/encounter";
import {Player} from "../../Classes/player";
import {EncounterService} from "../../Services/encounter.service";
import {AddPlayerFormComponent} from "../add-player-form/add-player-form.component";
import Compressor from "compressorjs";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-edit-local-players',
  templateUrl: './edit-local-players.component.html',
  styleUrls: ['./edit-local-players.component.scss']
})
export class EditLocalPlayersComponent implements OnInit, OnDestroy {
  destroy$:Subject<boolean> = new Subject();
  mode:editMode = editMode.BASE;
  icons = new NpcIcons();
  encounter:Encounter;
  localPlayers:Player[];
  @ViewChild(AddPlayerFormComponent) addPlayerComponent:AddPlayerFormComponent;
  constructor(public encounterService:EncounterService, private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.encounterService.localPlayers$.pipe(takeUntil(this.destroy$)).subscribe(this.localPlayersUpdated);
  }

  localPlayersUpdated = (players:Player[])=>{
    const _self:EditLocalPlayersComponent = this;
    this.localPlayers = players;
    // check image size and make sure it isn't too big;
    this.localPlayers.forEach((p)=>{
      const file = p.icon === ''? undefined:this.dataURLtoFile(p.icon,'temp');
      if(file && file.size>(1024*1024)){
        new Compressor(file, {
          width:64,
          height:64,
          resize:'cover',
          convertSize:1000000*0.5, //half meg
          success(result) {
            console.log('compressed file size',(1024/result.size));
            const reader = new FileReader();
            reader.readAsDataURL(result);
            reader.onload= () => {
              const result = _self.domSanitizer.bypassSecurityTrustUrl(reader.result as string);
              p.icon = _self.domSanitizer.sanitize(SecurityContext.URL, result);
            }
          }
        })
      }
    });
  }

  dataURLtoFile(dataurl:string, filename:string) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }

  deleteLocalPlayer(p:Player){
    const index = this.localPlayers.findIndex(d=>d.id === p.id);
    this.encounterService.deleteLocalStoragePlayer(this.localPlayers[index]);
  }

  openAddPlayerForm(player?:Player){
    this.mode = editMode.ADD_CREATURE;
    if(player){
      this.addPlayerComponent.playerForm.reset({
        id:player.id,
        name:player.name,
        initBonus:player.initBonus,
        fileName:''
      });
      this.addPlayerComponent.uploadedIcon = player.icon;
    } else {
      this.addPlayerComponent.playerForm.reset({
        id:'',
        name:'',
        initBonus:0,
        fileName:''
      });
      this.addPlayerComponent.uploadedIcon='';
    }
  }

  closeAddPlayer(){
    this.mode = editMode.BASE;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
