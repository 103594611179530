import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Character} from "../../Classes/character";
import {EncounterService} from "../../Services/encounter.service";

@Component({
  selector: 'app-run-turn-form',
  templateUrl: './run-turn-form.component.html',
  styleUrls: ['./run-turn-form.component.scss']
})
export class RunTurnFormComponent implements OnInit {
  @Output() closeFormEvent = new EventEmitter<void>();
  _character:Character;
  get character(){
    return this._character;
  }
  set character(ch){
    this._character = ch;
    this.turnForm.reset({
      hidden:ch.hidden,
      dead:ch.dead,
      incapacitated:ch.incapacitated,
      debilitated:ch.debilitated,
      hp:ch.hp,
      notes:ch.notes,
      playerNotes:ch.playerNotes
    })
  }
  turnForm:FormGroup = this.fb.group(
    {
      hidden:[false],
      dead:[false],
      incapacitated:[0],
      debilitated:[false],
      hp:[0],
      notes:[''],
      playerNotes:['']
    }
  );
  constructor(public encounterService:EncounterService, public fb:FormBuilder) { }

  ngOnInit(): void {}

  submit(){
    if(!this.character.owner) {
      this.character.hidden = this.turnForm.getRawValue().hidden;
      this.character.hp = this.turnForm.getRawValue().hp;
    }
    this.character.dead = this.turnForm.getRawValue().dead;
    this.character.incapacitated = this.turnForm.getRawValue().incapacitated;
    this.character.debilitated = this.turnForm.getRawValue().debilitated;
    this.character.notes = this.turnForm.getRawValue().notes;
    this.character.playerNotes = this.turnForm.getRawValue().playerNotes;
    this.turnForm.reset({hidden:false,dead:false,incapacitated:0,debilitated:false,hp:0,notes:'',playerNotes:''});
    this.closeFormEvent.emit();
  }
  cancel(){
    this.turnForm.reset({hidden:false,dead:false,incapacitated:0,debilitated:false,hp:0,notes:'',playerNotes:''});
    this.closeFormEvent.emit();
  }
}
