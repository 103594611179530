export class NpcIcon {
  public static prefix:string = 'assets/icons/creatures/'
  name:string = '';
  path:string = '';
  meta:string[] = [];
  constructor(name:string, path:string,meta:string[]) {
    this.name = name;
    this.path = path;
    this.meta = meta;
  }
}

export class NpcIcons {
  icons:NpcIcon[] = [
    new NpcIcon('Brute',NpcIcon.prefix+'brute.svg',['brute','monster','orc','ork','minion','grunt']),
    new NpcIcon('Alien Bug',NpcIcon.prefix+'alien-bug.svg',['alien','bug']),
    new NpcIcon('Alien Egg',NpcIcon.prefix+'alien-egg.svg',['alien','egg']),
    new NpcIcon('Anubis',NpcIcon.prefix+'anubis.svg',['anubis','egyptian','egypt','god']),
    new NpcIcon('Bad Gnome',NpcIcon.prefix+'bad-gnome.svg',['gnome','halfling']),
    new NpcIcon('Beast Eye', NpcIcon.prefix+'beast-eye.svg',['beast','dragon','eye']),
    new NpcIcon('Beholder',NpcIcon.prefix+'behold.svg',['beholder','monster']),
    new NpcIcon('Beastial Fangs',NpcIcon.prefix+'bestial-fangs.svg',['skull','fangs','beast']),
    new NpcIcon('Bottled Shadow',NpcIcon.prefix+'bottled-shadow.svg',['bottle','shadow','ghost']),
    new NpcIcon('Brain Tentacle',NpcIcon.prefix+'brain-tentacle.svg',['brain','tentacle','squid']),
    new NpcIcon('Bullet',NpcIcon.prefix+'bullet-bill.svg',['bullet']),
    new NpcIcon('Bully Minion',NpcIcon.prefix+'bully-minion.svg',['brute','monster','orc','ork','minion','grunt']),
    new NpcIcon('Carnivorous Plant',NpcIcon.prefix+'carnivorous-plant.svg',['plant','horror']),
    new NpcIcon('Ceiling Barnacle',NpcIcon.prefix+'ceiling-barnacle.svg',['plant','horror','tentacles']),
    new NpcIcon('Centaur',NpcIcon.prefix+'centaur.svg',['centaur','humanoid']),
    new NpcIcon('Cloaked Figure',NpcIcon.prefix+'cloaked-figure-on-horseback.svg',['ghoul','humanoid','ghost']),
    new NpcIcon('Cracked Alien Skull',NpcIcon.prefix+'cracked-alien-skull.svg',['skull','skeleton','alien']),
    new NpcIcon('Cyclops',NpcIcon.prefix+'cyclops.svg',['cyclops','eye','humanoid']),
    new NpcIcon('Tentacle Monster',NpcIcon.prefix+'daemon-pull.svg',['eldritch','horror','tentacle','monster']),
    new NpcIcon('Demon Skull',NpcIcon.prefix+'daemon-skull.svg',['demon','skull']),
    new NpcIcon('Devil Mask',NpcIcon.prefix+'devil-mask.svg',['devil','demon','skull']),
    new NpcIcon('Diablo Skull',NpcIcon.prefix+'diablo-skull.svg',['devil','demon','skull']),
    new NpcIcon('Dinosaur Bones',NpcIcon.prefix+'dinosaur-bones.svg',['dinosaur','bones','skeleton']),
    new NpcIcon('Dinosaur Egg',NpcIcon.prefix+'dinosaur-egg.svg',['dinosaur','egg']),
    new NpcIcon('Dinosaur Rex',NpcIcon.prefix+'dinosaur-rex.svg',['dinosaur','rex','lizard','dragon']),
    new NpcIcon('Djinn',NpcIcon.prefix+'djinn.svg',['elemental','djinn','genie']),
    new NpcIcon('Double Dragon',NpcIcon.prefix+'double-dragon.svg',['dragon','monster']),
    new NpcIcon('Dragon Head',NpcIcon.prefix+'dragon-head.svg',['dragon','monster']),
    new NpcIcon('Dragon Spiral',NpcIcon.prefix+'dragon-spiral.svg',['dragon','monster']),
    new NpcIcon('Drakkar Dragon',NpcIcon.prefix+'drakkar-dragon.svg',['dragon','monster']),
    new NpcIcon('Egyptian Sphinx',NpcIcon.prefix+'egyptian-sphinx.svg',['Egyptian','monster']),
    new NpcIcon('Elysium Shade',NpcIcon.prefix+'elysium-shade.svg',['ghost','monster']),
    new NpcIcon('Ent Mouth',NpcIcon.prefix+'ent-mouth.svg',['ent','monster']),
    new NpcIcon('Evil Bat',NpcIcon.prefix+'evil-bat.svg',['bat','monster']),
    new NpcIcon('Evil Book',NpcIcon.prefix+'evil-book.svg',['book','monster','mimic','necronomicon']),
    new NpcIcon('Evil Comet',NpcIcon.prefix+'evil-comet.svg',['elemental','monster']),
    new NpcIcon('Evil Fork',NpcIcon.prefix+'evil-fork.svg',['devil','monster','demon']),
    new NpcIcon('Evil Minion',NpcIcon.prefix+'evil-minion.svg',['minion','monster','devil']),
    new NpcIcon('Evil Moon',NpcIcon.prefix+'evil-moon.svg',['elemental','monster']),
    new NpcIcon('Evil Tree',NpcIcon.prefix+'evil-tree.svg',['ent','monster','tree']),
    new NpcIcon('Eye Stalk',NpcIcon.prefix+'eyestalk.svg',['eye','monster']),
    new NpcIcon('Fairy',NpcIcon.prefix+'fairy.svg',['fairy','fey']),
    new NpcIcon('Female Vampire',NpcIcon.prefix+'female-vampire.svg',['vampire','monster']),
    new NpcIcon('Fish Monster',NpcIcon.prefix+'fish-monster.svg',['fish','monster']),
    new NpcIcon('Fleshy Mass',NpcIcon.prefix+'fleshy-mass.svg',['abomination','monster']),
    new NpcIcon('Floating Ghost',NpcIcon.prefix+'floating-ghost.svg',['ghost','ghoul']),
    new NpcIcon('Floating Tentacles',NpcIcon.prefix+'floating-tentacles.svg',['abomination','monster','eldritch']),
    new NpcIcon('Fomorian',NpcIcon.prefix+'fomorian.svg',['humanoid','monster','ghoul','ghost']),
    new NpcIcon('Frankenstein Creature',NpcIcon.prefix+'frankenstein-creature.svg',['abomination','monster','frankenstein']),
    new NpcIcon('Gargoyle',NpcIcon.prefix+'gargoyle.svg',['gargoyle','monster']),
    new NpcIcon('Ghost',NpcIcon.prefix+'ghost.svg',['ghost','monster']),
    new NpcIcon('Giant',NpcIcon.prefix+'giant.svg',['giant','humanoid']),
    new NpcIcon('Gluttonous Smile',NpcIcon.prefix+'gluttonous-smile.svg',['abomination','monster']),
    new NpcIcon('Goblin',NpcIcon.prefix+'goblin.svg',['goblin','monster']),
    new NpcIcon('Goblin Head',NpcIcon.prefix+'goblin-head.svg',['goblin','monster']),
    new NpcIcon('Golem Head',NpcIcon.prefix+'golem-head.svg',['golem','monster']),
    new NpcIcon('Gooey Demon',NpcIcon.prefix+'gooey-daemon.svg',['devil','demon']),
    new NpcIcon('Grasping Slug',NpcIcon.prefix+'grasping-slug.svg',['abomination','monster']),
    new NpcIcon('Greek Sphinx',NpcIcon.prefix+'greek-sphinx.svg',['sphinx','monster']),
    new NpcIcon('Griffin Symbol',NpcIcon.prefix+'griffin-symbol.svg',['griffin','monster','crest']),
    new NpcIcon('Grim Reaper',NpcIcon.prefix+'grim-reaper.svg',['death','reaper','grim','ghoul']),
    new NpcIcon('Half Body Crawling',NpcIcon.prefix+'half-body-crawling.svg',['zombie','ghoul']),
    new NpcIcon('Harpy',NpcIcon.prefix+'harpy.svg',['harpy','monster','humanoid']),
    new NpcIcon('Haunting',NpcIcon.prefix+'haunting.svg',['haunting','ghost','ghoul']),
    new NpcIcon('Horned Reptile',NpcIcon.prefix+'horned-reptile.svg',['lizard','monster']),
    new NpcIcon('Horned Skull',NpcIcon.prefix+'horned-skull.svg',['skull','monster']),
    new NpcIcon('Hydra',NpcIcon.prefix+'hydra.svg',['hydra','monster','dragon']),
    new NpcIcon('Hydra Shot',NpcIcon.prefix+'hydra-shot.svg',['hydra','monster','dragon']),
    new NpcIcon('Ice Golem',NpcIcon.prefix+'ice-golem.svg',['ice','giant','humanoid','golem']),
    new NpcIcon('Ifrit',NpcIcon.prefix+'ifrit.svg',['ifrit','monster','djinn','elemental']),
    new NpcIcon('Imp',NpcIcon.prefix+'imp.svg',['imp','devil','demon']),
    new NpcIcon('Imp Laugh',NpcIcon.prefix+'imp-laugh.svg',['imp','devil','demon']),
    new NpcIcon('Infested Mass',NpcIcon.prefix+'infested-mass.svg',['abomination','monster']),
    new NpcIcon('Jawless Cyclops',NpcIcon.prefix+'jawless-cyclop.svg',['cyclops','monster']),
    new NpcIcon('Kenku Head',NpcIcon.prefix+'kenku-head.svg',['kenku','humanoid','bird']),
    new NpcIcon('Kraken Tentacle',NpcIcon.prefix+'kraken-tentacle.svg',['hydra','kraken','dragon','eldritch']),
    new NpcIcon('Lizard Man',NpcIcon.prefix+'lizardman.svg',['lizard','humanoid','dragon']),
    new NpcIcon('Medusa Head',NpcIcon.prefix+'medusa-head.svg',['medusa','monster','gorgon']),
    new NpcIcon('Mermaid',NpcIcon.prefix+'mermaid.svg',['mermaid','monster','humanoid']),
    new NpcIcon('Metroid',NpcIcon.prefix+'metroid.svg',['metroid','alien','monster']),
    new NpcIcon('Mimic Chest',NpcIcon.prefix+'mimic-chest.svg',['mimic','chest']),
    new NpcIcon('Minotaur',NpcIcon.prefix+'minotaur.svg',['minotaur','monster','humanoid']),
    new NpcIcon('Mummy Head',NpcIcon.prefix+'mummy-head.svg',['mummy','monster','humanoid','egyptian']),
    new NpcIcon('Ogre',NpcIcon.prefix+'ogre.svg',['ogre','monster','humanoid']),
    new NpcIcon('Oni',NpcIcon.prefix+'oni.svg',['oni','monster','demon']),
    new NpcIcon('Orc Head',NpcIcon.prefix+'orc-head.svg',['orc','monster','humanoid']),
    new NpcIcon('Pick of Destiny',NpcIcon.prefix+'pick-of-destiny.svg',['monster','demon']),
    new NpcIcon('Pretty Fangs',NpcIcon.prefix+'pretty-fangs.svg',['vampire','monster','humanoid']),
    new NpcIcon('Purple Tentacle',NpcIcon.prefix+'purple-tentacle.svg',['eldritch','monster','tentacle']),
    new NpcIcon('Resting Vampire',NpcIcon.prefix+'resting-vampire.svg',['vampire','monster','humanoid']),
    new NpcIcon('Rock Golem',NpcIcon.prefix+'rock-golem.svg',['golem','elemental','humanoid']),
    new NpcIcon('Sasquatch',NpcIcon.prefix+'sasquatch.svg',['sasquatch','yeti','humanoid']),
    new NpcIcon('Sea Creature',NpcIcon.prefix+'sea-creature.svg',['lizard','monster']),
    new NpcIcon('Shambling Mound',NpcIcon.prefix+'shambling-mound.svg',['golem','monster','abomination']),
    new NpcIcon('Shambling Zombie',NpcIcon.prefix+'shambling-zombie.svg',['zombie','monster','humanoid']),
    new NpcIcon('Sharp Smile',NpcIcon.prefix+'sharp-smile.svg',['skull','monster','vampire']),
    new NpcIcon('Slime',NpcIcon.prefix+'slime.svg',['abomination','monster']),
    new NpcIcon('Spark Spirit',NpcIcon.prefix+'spark-spirit.svg',['elemental','monster','fire']),
    new NpcIcon('Spectre',NpcIcon.prefix+'spectre.svg',['spectre','monster','ghost','ghoul']),
    new NpcIcon('Spider Eye',NpcIcon.prefix+'spider-eye.svg',['spider','monster','eye']),
    new NpcIcon('Spiked Dragon Head',NpcIcon.prefix+'spiked-dragon-head.svg',['dragon','monster','drake']),
    new NpcIcon('Swallower',NpcIcon.prefix+'swallower.svg',['slime','monster','abomination']),
    new NpcIcon('Swamp Bat',NpcIcon.prefix+'swamp-bat.svg',['bat','monster','vampire']),
    new NpcIcon('Thwomp',NpcIcon.prefix+'thwomp.svg',['thwomp','monster','elemental']),
    new NpcIcon('Toad Teeth',NpcIcon.prefix+'toad-teeth.svg',['toad','monster','humanoid']),
    new NpcIcon('Transparent Slime',NpcIcon.prefix+'transparent-slime.svg',['slime','monster','abomination']),
    new NpcIcon('Tree Face',NpcIcon.prefix+'tree-face.svg',['ent','monster','tree']),
    new NpcIcon('Triton Head',NpcIcon.prefix+'triton-head.svg',['fish','monster','humanoid']),
    new NpcIcon('Troglodyte',NpcIcon.prefix+'troglodyte.svg',['abomination','monster','humanoid']),
    new NpcIcon('Troll',NpcIcon.prefix+'troll.svg',['troll','monster','humanoid']),
    new NpcIcon('Unfriendly Fire',NpcIcon.prefix+'unfriendly-fire.svg',['elemental','monster','djinn']),
    new NpcIcon('Unicorn',NpcIcon.prefix+'unicorn.svg',['unicorn','monster']),
    new NpcIcon('Vampire Dracula',NpcIcon.prefix+'vampire-dracula.svg',['vampire','monster','humanoid']),
    new NpcIcon('Werewolf',NpcIcon.prefix+'werewolf.svg',['werewolf','monster','humanoid']),
    new NpcIcon('Witch Face',NpcIcon.prefix+'witch-face.svg',['witch','monster','humanoid']),
    new NpcIcon('Witch Flight',NpcIcon.prefix+'witch-flight.svg',['witch','monster','humanoid']),
    new NpcIcon('Wyvern',NpcIcon.prefix+'wyvern.svg',['wyvern','monster','dragon'])
  ]
}
